(function () {
  'use strict';

  /* @ngdoc object
   * @name events
   * @description
   *
   */
  angular
    .module('events', [
      'ui.router',
      'events.vehicles',
      'events.guests',
      'events.view',
      'events.people',
      'events.reports'
    ]);
}());
